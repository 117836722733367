import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajesService } from 'src/app/services/mensajes.service';
import { AuthenticateService } from '../../services/authenticate.service'
import { DepartmentsService } from 'src/app/services/departments.service';

@Component({
  selector: 'app-expositor',
  templateUrl: './expositor.component.html',
  styleUrls: ['./expositor.component.css']
})
export class ExpositorComponent implements OnInit {
  formularioExpositor: FormGroup;
  porcentajeSubida = 0;
  urlImage = '';
  edit:boolean;
  enableEmail:boolean;
  id: string;
  user:string;
  role:string;
  isNameSelected: boolean;
  EstadoHabilitado:string='';
  public contrasena: any = {};
  private categorias = [];

  private departamentos = [];
  private ciudades = [];


  constructor(private formBuilder: FormBuilder,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private activeRoute: ActivatedRoute,
              private msj: MensajesService,
              private router: Router,
              private authSvc: AuthenticateService,
              private deptoSvc: DepartmentsService)
  {
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  selectInput(event) {
    let selected = event.target.value;
    if (selected == "Suspendido") {
      this.isNameSelected = true;
    } else {
      this.isNameSelected = false;
    }
  }

  ngOnInit() {
    this.validate();
    this.loadCategorias();
    this.loadDepartamentos();
    this.id = this.activeRoute.snapshot.params.expositorID;
     if (this.id != undefined && this.role=='Admin')
    {
      this.enableEmail = false;
      this.edit=true;
      this.db.doc<any>('Expositores' + '/' + this.id).valueChanges().subscribe((expositor) =>{
        this.setForm(expositor);

      });
    }

    if (this.id == undefined && this.role=='Expositor')
    {
      this.enableEmail = false;
      this.edit = true;
      let email =this.user.toLowerCase().trim();
      this.db.collection('Expositores',ref => ref.where('Email', '==', email)).get().subscribe((expositores) => {
        expositores.docs.forEach((item) => {
            const expositor = item.data();
            this.id=item.id;
            this.setForm(expositor);
        });
      });
      this.formularioExpositor.controls['Estado'].disable();
      this.formularioExpositor.controls['MotivoSuspension'].disable();
    }

    if (this.id == undefined && this.role=='Admin')
    {
      this.enableEmail = true;
      this.edit = false;
    }

  }

  loadDepartamentos(){
    // this.deptoSvc.getDepartamentos().subscribe((deptos: any) => {
    //   this.departamentos = deptos;
    // })
    this.departamentos = this.deptoSvc.getDepartamentos();
  }

  onDepartamento(value){
    const departamento = this.departamentos.find(departamento => departamento.departamento == value)
    this.ciudades = departamento.ciudades;

  }


  loadCategorias(){
    this.db.collection('Categorias').get()
    .subscribe(categorias => {
      categorias.docs.forEach(categoria => {
        this.categorias.push({
          data: categoria.data(),
          id: categoria.id
        })
      })
    })
  }

  onEdad(date){
    const dob = new Date(date);
    const month_diff = Date.now() - dob.getTime();
    const age_dt = new Date(month_diff);
    const year = age_dt.getUTCFullYear();
    const age = Math.abs(year - 1970)
    this.formularioExpositor.patchValue({
      Edad: age
    })
  }

  validateContrasena(passForm){
    if(passForm.form.valid){
      if(passForm.form.value.contrasena == passForm.form.value.rep_contrasena){
        this.addExpo(passForm.form.value.contrasena);
      }
      else {
        this.msj.mensajeError('Error', 'Contraseñas no coinciden')
      }
    }
    else {
      this.msj.mensajeError('Error', 'Contraseñas no coinciden')
    }
  }

  addExpo(contrasena)
  {
    if(this.formularioExpositor.valid){
      let exist=false;
      this.db.collection('Expositores').get().subscribe((expositores) => {
        expositores.docs.forEach((item) => {
          const expositor = item.data();
          if (expositor.Email== this.formularioExpositor.value.Email || expositor.Codigo == this.formularioExpositor.value.Codigo){
            exist=true;
          }
        });

        if(!exist){
          this.formularioExpositor.value.ImgUrl = this.urlImage;
          this.formularioExpositor.value.FechaCreacion = new Date();
          this.formularioExpositor.value.UsuarioCreacion = sessionStorage.getItem('User');
              this.formularioExpositor.value.FechaNacimiento = new Date(this.formularioExpositor.value.FechaNacimiento);
              this.db.collection('Expositores').add(this.formularioExpositor.value).then((termino) => {
                this.authSvc.createUserAuth(this.formularioExpositor.value.Email, contrasena).then(result => {
                  this.msj.mensajeCorrecto('Agregar', 'Expositor agregado correctamente');
                  this.formularioExpositor.reset();
                  this.urlImage = ""
                })
                .catch(err => this.msj.mensajeError('Error', err.message));
              });
              this.db.collection('HistorialExpositores').add(this.formularioExpositor.value).then((termino) => {
              });

        }
        else
        {
          this.msj.mensajeError('Error', 'Expositor con email o codigo repetido');
        }
      });
    }
    else {
      this.msj.mensajeError('Error', 'Ingrese los campos obligatorios');
    }

  }

  editExpo()
  {
    if(this.formularioExpositor.valid){
      this.formularioExpositor.value.ImgUrl = this.urlImage;
      this.formularioExpositor.value.FechaNacimiento = new Date(this.formularioExpositor.value.FechaNacimiento);
      this.formularioExpositor.value.FechaActualizacion = new Date();
      this.formularioExpositor.value.UsuarioActualizacion = sessionStorage.getItem('User');
      this.db.doc('Expositores/' + this.id).update(this.formularioExpositor.value).then((resultado) => {
        this.db.collection('Productos', ref => ref.where('User', '==', this.formularioExpositor.value.Email)).get()
        .subscribe(productos => {
          productos.docs.forEach(producto => {
            const data = producto.data();
            data.TallerLineaProducto = this.formularioExpositor.value.LineaProducto1;
            data.TallerEstado = this.formularioExpositor.value.Estado;
            data.CostoEnvioLocal = this.formularioExpositor.value.CostoEnvioLocal;
            data.CostoEnvioNacional = this.formularioExpositor.value.CostoEnvioNacional;
            const id = producto.id;
            this.db.collection('Productos').doc(id).update(data)
            .then(result => {
            })
          })
          this.msj.mensajeCorrecto('Editado', 'Expositor editado correctamente');

        })
      }).catch(error => {
        this.msj.mensajeError('Error', 'Se ha producido un error al editar el Expositor');
      });

      this.db.collection('HistorialExpositores').add(this.formularioExpositor.value).then((termino) => {
      });
    }
    else {
      this.msj.mensajeError('Error', 'Ingrese los campos obligatorios');
    }

  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    {
      let nombre = new Date().getTime().toString();
      let archivo = event.target.files[0];
      if (archivo.size < 1024000)
      {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Expositores/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.urlImage = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
      }
      else
      {
        this.msj.mensajeError('Agregar', 'La imagen excede 1 MB');
      }
    }
  }

  validate(){
    this.formularioExpositor = this.formBuilder.group({
      Estado: ['', Validators.required],
      MotivoSuspension: [''],
      Email: ['', Validators.required],
      Nombres: ['', Validators.required],
      Apellidos: ['', Validators.required],
      // Descripcion: [''],
      Codigo: ['', Validators.required],
      EpaycoID: [''],
      Comision: [''],
      Iva: [''],
      Condicion:['', Validators.required],
      Identificacion: ['', Validators.required],
      TipoIdentificacion: ['', Validators.required],
      Edad: [{value: '', disabled: true}],
      Sexo: [''],
      FechaNacimiento: [''],
      LugarNacimiento: [''],
      EstadoCivil: [''],
      NumeroHijos: [''],
      PersonasCargo: [''],
      EstratoSocioeconomico: [''],
      // GrupoEtnico: [''],
      // SectorSocial: [''],
      FormacionAcademica: [''],
      // UltimoTituloObtenido: [''],
      Direccion: [''],
      Departamento: ['', Validators.required],
      Ciudad: ['', Validators.required],
      // Localidad: [''],
      // Barrio: [''],
      // TipoVivienda: [''],
      TelefonoFijo: [''],
      TelefonoMovil: [''],
      // NombreContactoEmergencia: [''],
      // TelefonoContactoEmergencia: [''],
      VinculadoSalud: [''],
      // RegimenSalud: [''],
      EPS: [''],
      // TipoSangre: [''],
      NombreTaller: [''],
      // AnoCreacionTaller: [''],
      // AnoIngresoMercado: [''],
      // PersonasVinculadasTaller: [''],
      UbicacionTaller: [''],
      Nit: [''],
      CamaraComercio: [''],
      // IngresosMensualesTaller: [''],
      PaginaWeb: [''],
      FacebookUrl: [''],
      InstagramUrl: [''],
      ImgUrl: [''],
      LineaProducto1: [''],
      CostoEnvioNacional: ['', Validators.required],
      CostoEnvioLocal: ['', Validators.required]
    });
  }

  setForm(expositor){
    if(expositor.Estado=="Suspendido"){
      this.isNameSelected=true;
    }
    if(expositor.Departamento){
      this.onDepartamento(expositor.Departamento);
    }

    this.formularioExpositor.patchValue({
      Estado:expositor.Estado==undefined ? "Activo":expositor.Estado,
      MotivoSuspension:expositor.MotivoSuspension==undefined ? " ":expositor.MotivoSuspension,
      Email: expositor.Email,
      Nombres: expositor.Nombres,
      Apellidos: expositor.Apellidos,
      // Descripcion: expositor.Descripcion,
      Codigo: expositor.Codigo,
      EpaycoID: expositor.EpaycoID >= 0 ? expositor.EpaycoID : null,
      Comision: expositor.Comision >= 0 ? expositor.Comision : null,
      Iva: expositor.Iva >= 0 ? expositor.Iva : null,
      Condicion:expositor.Condicion,
      Identificacion: expositor.Identificacion,
      TipoIdentificacion: expositor.TipoIdentificacion,
      Edad: expositor.Edad,
      Sexo: expositor.Sexo,
      FechaNacimiento:  (expositor.FechaNacimiento) ? new Date (expositor.FechaNacimiento.seconds * 1000).toISOString().substr(0,10): null,
      LugarNacimiento: expositor.LugarNacimiento,
      EstadoCivil: expositor.EstadoCivil,
      NumeroHijos: expositor.NumeroHijos,
      PersonasCargo: expositor.PersonasCargo,
      EstratoSocioeconomico: expositor.EstratoSocioeconomico,
      // GrupoEtnico: expositor.GrupoEtnico,
      // SectorSocial: expositor.SectorSocial,
      FormacionAcademica: expositor.FormacionAcademica,
      // UltimoTituloObtenido: expositor.UltimoTituloObtenido,
      Direccion: expositor.Direccion,
      Departamento: expositor.Departamento,
      Ciudad: expositor.Ciudad,
      // Localidad: expositor.Localidad,
      // Barrio: expositor.Barrio,
      // TipoVivienda: expositor.TipoVivienda,
      TelefonoFijo: expositor.TelefonoFijo,
      TelefonoMovil: expositor.TelefonoMovil,
      // NombreContactoEmergencia: expositor.NombreContactoEmergencia,
      // TelefonoContactoEmergencia: expositor.TelefonoContactoEmergencia,
      VinculadoSalud: expositor.VinculadoSalud,
      // RegimenSalud: expositor.RegimenSalud,
      EPS: expositor.EPS,
      // TipoSangre: expositor.TipoSangre,
      NombreTaller: expositor.NombreTaller,
      AnoCreacionTaller: expositor.AnoCreacionTaller,
      AnoIngresoMercado: expositor.AnoIngresoMercado,
      PersonasVinculadasTaller: expositor.PersonasVinculadasTaller,
      UbicacionTaller:expositor.UbicacionTaller,
      Nit: expositor.Nit,
      CamaraComercio: expositor.CamaraComercio,
      IngresosMensualesTaller: expositor.IngresosMensualesTaller,
      PaginaWeb: expositor.PaginaWeb,
      FacebookUrl: expositor.FacebookUrl,
      InstagramUrl: expositor.InstagramUrl,
      ImgUrl: '',
      LineaProducto1: expositor.LineaProducto1,
      LineaProducto2: expositor.LineaProducto2,
      LineaProducto3: expositor.LineaProducto3,
      LineaProducto4: expositor.LineaProducto4,
      LineaProducto5: expositor.LineaProducto5,
      LineaProducto6: expositor.LineaProducto6,
      CostoEnvioNacional: expositor.CostoEnvioNacional,
      CostoEnvioLocal: expositor.CostoEnvioLocal

      });
    this.urlImage = expositor.ImgUrl;
  }
}
