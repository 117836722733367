import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faEye, faCheck, faPaperPlane, faArchive, faSpinner, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { CorreosService } from 'src/app/services/correos.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.css']
})

export class OrdenesComponent implements  OnInit {
  /*Icons*/
  faEye = faEye;
  faCheck = faCheck;
  faPaperPlane = faPaperPlane;
  faArchive = faArchive;
  faSpinner = faSpinner;
  faEllipsis = faEllipsisH;


  idOrden:string="";
  formularioOrden: FormGroup;
  formularioModo:string="lista";
  user:string="";
  role:string="";
  arrayOrdenes:String[]=[];
  orden:any;
  producto:any;
  idProducto="";
  estado="";
  notaEstado="";
  closeResult: string;
  urlFile="";
  searchOrdenes = [];
  searchProductos = [];

  constructor(private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private msj: MensajesService,
              private activeRoute: ActivatedRoute,
              private emailSvc: CorreosService) {
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    if(this.role=="Admin")
    {
      this.loadTable("Pendiente");
    }
    else
    {
      this.loadTableCurrentUser("Pendiente");
    }
  }

  public loadTable(estado){
    this.searchOrdenes = [];
    this.arrayOrdenes=[];
    this.db.collection('Ordenes',ref => ref.where('Estado', '==', estado)).get().subscribe((ordenes: any) => {
      ordenes.docs.forEach((item) => {
        const orden = item.data();
        let productosOrden = [...orden.Productos];
        if(productosOrden.filter(producto => producto.Estado === 'Por Enviar').length == productosOrden.length){
          orden.icon = this.faEllipsis
        }
        else if(productosOrden.filter(producto => producto.Estado === 'Recibido').length == productosOrden.length){
          orden.icon = this.faCheck
        }
        else {
          orden.icon = this.faSpinner;
        }
        orden.id=item.id;
        this.arrayOrdenes.push(orden);
        this.searchOrdenes.push(orden);
      });
    });
  }

  public exportCSV() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayOrdenes);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Ordenes');
    XLSX.writeFile(wb, "ordenes.xlsx");
  }

  public loadTableCurrentUser(estado){
    this.searchOrdenes = [];
    this.arrayOrdenes=[];
    this.db.collection('Ordenes',ref => ref.where('Estado', '==', estado)).get().subscribe((ordenes:any) => {
      ordenes.docs.forEach((item) => {
        const orden = item.data();
        orden.id=item.id;
        orden.Productos.forEach((itemProducto) => {
          if(itemProducto.User==this.user)
          {
            this.arrayOrdenes.push(orden);
            this.searchOrdenes.push(orden);
            return;
          }
        });
      });
    });
  }

  closeForm(){
    this.formularioModo="lista";
  }

  openFormEdit(idOrden){
    this.idOrden=idOrden;
    this.formularioModo="edicion";
    this.db.doc<any>('Ordenes' + '/' + idOrden).valueChanges().subscribe((orden) =>{
      this.setForm(orden);
    });
  }

  setForm(orden){
    this.formularioOrden.patchValue({
      Nombre: orden.Nombre,
      Descripcion: orden.Descripcion,
      DescripcionCorta: orden.DescripcionCorta,
      Valor: orden.Valor,
      ValorConDescuento: orden.ValorConDescuento,
      PorcentajeDescuento: orden.PorcentajeDescuento,
      Stock: orden.Stock,
      ProductoNuevo: orden.ProductoNuevo,
      Saldo: orden.Saldo,
      Tags: orden.Tags,
    });
  }

  buscarOrdenes(value){
    if(this.role=="Admin")
    {
      this.loadTable(value);
    }
    else
    {
      this.loadTableCurrentUser(value);
    }
  }

  openOrder(orden){
    if(this.role=="Admin"){
      this.formularioModo="productos";
      this.orden=orden;
      this.searchProductos = [...this.orden.Productos];
    }
    else {
      this.formularioModo="productos";
      this.orden = orden;
      this.orden.Productos = this.orden.Productos.filter(producto => producto.User == this.user);
    }
  }

  onExpositor(value){
    this.orden.Productos.splice(0, this.orden.Productos.length);
    let temp = this.searchProductos.filter((d) => {
      return d.User.indexOf(value) !== -1 || !value;
    });
    this.orden.Productos.push(...temp);
  }




  finalizarOrden(orden){
    let finalizar=1;
    for (let index = 0; index < orden.Productos.length; index++) {
      if(orden.Productos[index].Estado!="Finalizado")
      {
        finalizar=0;
      }
    }

    if(finalizar==0)
    {
      this.msj.mensajeError('Finalizar orden', 'No se puede finalizar la orden, revice el estado de los productos.');
    }
    else
    {
      orden.Estado="Finalizado";
      this.db.doc('Ordenes/' + orden.id).update(orden).then((item) => {
        this.msj.mensajeCorrecto('Finalizar orden', 'Orden finalizada correctamente');
        if(this.role=="Admin")
        {
          this.loadTable("Pendiente");
        }
        else
        {
          this.loadTableCurrentUser("Pendiente");
        }
      });
    }
  }

  cambiarEstado(){
    for (let index = 0; index < this.orden.Productos.length; index++) {
      if(this.orden.Productos[index].idProducto==this.idProducto)
      {
        this.orden.Productos[index].Estado=this.estado;

        if(this.estado=="Enviado")
        {
          this.orden.Productos[index].urlArchivoEnviado=this.urlFile;
          this.orden.Productos[index].FechaEstadoEnviado=new Date().toString();
          this.orden.Productos[index].NotaEstadoEnviado=this.notaEstado;
        }
        if(this.estado=='Recogido')
        {
          this.orden.Productos[index].urlArchivoEnviado=this.urlFile;
          this.orden.Productos[index].FechaEstadoRecogido=new Date().toString();
          this.orden.Productos[index].NotaEstadoRecogido=this.notaEstado;
        }
        if(this.estado=="Recibido")
        {
          this.orden.Productos[index].urlArchivoRecibido=this.urlFile;
          this.orden.Productos[index].FechaEstadoRecibido=new Date().toString();
          this.orden.Productos[index].NotaEstadoRecibido=this.notaEstado;
        }
        if(this.estado=="Finalizado")
        {
          this.orden.Productos[index].urlArchivoFinalizado=this.urlFile;
          this.orden.Productos[index].FechaEstadoFinalizado=new Date().toString();
          this.orden.Productos[index].NotaEstadoFinalizado=this.notaEstado;
        }

        delete this.orden.icon

        this.db.doc('Ordenes/' + this.orden.id).update(this.orden).then((item) => {
          const productoCorreo = {...this.orden.Productos[index]}
          productoCorreo.CompradorEmail = this.orden.CompradorEmail;
          productoCorreo.CompradorNombre = this.orden.CompradorNombre;
          productoCorreo.Estado = this.estado;
          productoCorreo.UrlArchivo = this.urlFile;
          this.emailSvc.enviarCorreoFlujo(productoCorreo).subscribe(() => {})
          this.msj.mensajeCorrecto('Producto '+this.estado, 'OK');
          this.notaEstado = "";
          this.orden=this.orden;
        });
      }
    }
  }

  open(content,orden,idProducto,estado) {
    this.orden=orden;
    this.idProducto=idProducto;
    this.estado=estado;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openDetailProduct(content,producto){
    this.producto=producto;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    {
        let nombre = this.orden.id +"-"+ this.idProducto +"-"+ this.estado;
        let archivo = event.target.files[0];
        if (archivo.size < 512000)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Ordenes/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.urlFile = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'El archivo excede 512 KB');
        }
    }
  }

  filtrarPorNumero(val){
    val = val.toString();
    this.arrayOrdenes.splice(0, this.arrayOrdenes.length);
    let temp = this.searchOrdenes.filter((d) => {
      val = val.toLowerCase();
      if(d.NumeroCompra){
        return d.NumeroCompra.toString().toLowerCase().indexOf(val) !== -1 || !val;
      }
    });
    this.arrayOrdenes.push(...temp);
  }
  filtrarPorComprador(val){
    val = val.toString();
    this.arrayOrdenes.splice(0, this.arrayOrdenes.length);
    let temp = this.searchOrdenes.filter((d) => {
      val = val.toLowerCase();
      return d.CompradorNombre.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.arrayOrdenes.push(...temp);

  }
  onDate(val){

    val = val.toString();
    this.arrayOrdenes.splice(0, this.arrayOrdenes.length);
    let temp = this.searchOrdenes.filter((d) => {
      return d.FechaCreacion.indexOf(val) !== -1 || !val;
    });
    this.arrayOrdenes.push(...temp);
  }

  // pruebaCorreo(){
  //   let image = 'https://firebasestorage.googleapis.com/v0/b/orlondonol-2e688.appspot.com/o/Productos%2F1598662657383.jpg?alt=media&token=0aa3ffee-0ac3-4bb0-a073-cab5ff52be99'
  //   let correo = 'maermora@hotmail.com'
  //   this.emailSvc.enviarCorreoAdjunto(image, correo).subscribe(result => {

  //   })

  // }
}
