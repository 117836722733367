import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public get currentRol() {
    return sessionStorage.getItem('Role');
  }

  public get currentUser() {
    return sessionStorage.getItem('User');
  }

  constructor(private authService: AuthenticateService) {

  }

  ngOnInit() {


  }
}
