import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CorreosService {

  private adminAPI = (location.hostname != 'localhost') ? 'https://us-central1-admin-infondo.cloudfunctions.net/email' : 'http://localhost:5001/admin-infondo/us-central1/email'
  private adminHeaders = new HttpHeaders()

  constructor(private httpClient: HttpClient) {
    this.adminHeaders.append('Content-Type','application/json')
  }

  public enviarCorreoEnviado(producto){
    return this.httpClient.post(`${this.adminAPI}/correo/enviado`, producto)
  }

  public enviarCorreoFlujo(producto){
    return this.httpClient.post(`${this.adminAPI}/correo/flujo`, producto)
  }


}
