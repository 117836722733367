import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
  providers: [CurrencyPipe]
})

export class ProductosComponent implements  OnInit {
  /*Icons*/
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;

  formularioProducto: FormGroup;
  formularioModo:string="lista";
  urlImage:string="";
  user:string="";
  expositor:string="";
  expositorInfo:any;
  role:string="";
  arrayProductos:String[]=[];
  porcentajeSubida=0;
  idProducto:string="";
  costoNoIncluido: boolean = false;
  params: any = {};

  urlImages = [];

  constructor(private formBuilder: FormBuilder,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private msj: MensajesService,
              private activeRoute: ActivatedRoute,
              private currencyPipe: CurrencyPipe,
              private parametros: ParametrosService) {
    this.user=sessionStorage.getItem('User');
    this.role=sessionStorage.getItem('Role');
  }

  ngOnInit() {
    this.validate();
    this.loadParametros();
    this.expositor = this.activeRoute.snapshot.params.expositorID;
    if(this.expositor && this.role=="Admin")
    {
      this.loadTable(this.expositor);
    }
    else
    {
      this.expositor=this.user;
      this.loadTable(this.expositor);
    }

  }

  private loadParametros(){
    this.parametros.getParametros().subscribe(parametros => {
      this.params = parametros.docs[0].data();

    })
  }

  public loadTable(expositor){
    this.arrayProductos=[];
    this.db.collection('Expositores',ref => ref.where('Email', '==', expositor)).get().subscribe((expositores:any) => {
      expositores.docs.forEach((item) => {
        const expositor = item.data();
          this.expositorInfo=expositor;
      });
    });
    this.db.collection('Productos',ref => ref.where('User', '==', expositor)).get().subscribe((productos:any) => {
      productos.docs.forEach((item) => {
        const producto = item.data();
          producto.id=item.id;
          this.arrayProductos.push(producto);
      });
    });
  }

  validate(){
    this.formularioProducto = this.formBuilder.group({
      Nombre: ['', Validators.required],
      Descripcion: ['',Validators.required],
      DescripcionCorta: ['',Validators.required],
      Valor: ['', Validators.required],
      ValorConDescuento: [{value: '', disabled: true},Validators.required],
      PorcentajeDescuento: ['',Validators.required],
      Stock: ['',Validators.required],
      EpaycoID: [''],
      Comision: [''],
      Iva: [''],
      // ProductoNuevo: ['',Validators.required],
      // Saldo: ['',Validators.required],
      Tags: [''],
      ImgUrl: ['',Validators.required],
      Imagenes: [[]],
      TiempoEntrega: ['',Validators.required],
    });
  }

  uploadImage(event)
  {
    if (event.target.files.length > 0)
    {
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if (archivo.size < this.params.ProductosPeso)
        {
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = 'Productos/' + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) =>{
              this.urlImage = url;
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else
        {
          this.msj.mensajeError('Agregar', 'La imagen excede 512 KB');
        }
    }
  }

  // updateImages(){

  // }

  eliminarImagen(index){
    this.formularioProducto.value.ImgUrl = this.urlImage;
    this.urlImages.splice(index, 1);
      this.formularioProducto.value.Imagenes = this.urlImages;
      this.formularioProducto.value.FechaModificacion = new Date();
      this.formularioProducto.value.UsuarioModificacion = sessionStorage.getItem('User');
      this.db.doc('Productos/' + this.idProducto).update(this.formularioProducto.value).then((item) => {
        this.msj.mensajeCorrecto('Actualizado', 'Producto actualizado correctamente');
        this.urlImage="";
        this.porcentajeSubida=0;
        this.formularioProducto.reset();
        this.loadTable(this.expositor);
        this.closeForm();
        let refFromUrl = this.storage.storage.refFromURL(this.urlImages[index]);
        refFromUrl.delete().then(result => { })
      });
      this.db.collection('HistorialProductos').add(this.formularioProducto.value).then((item) => {
      });
  }

  uploadImages(event){
    if (event.target.files.length > 0){
      if(this.urlImages.length < 3){
        let nombre = new Date().getTime().toString();
        let archivo = event.target.files[0];
        if(archivo.size < 512000){
          let extension = archivo.name.toString().substring(archivo.name.toString().lastIndexOf('.'));
          let ruta = `Productos/` + nombre + extension;
          const referencia = this.storage.ref(ruta);
          const tarea = referencia.put(archivo);
          tarea.then((objeto) => {
            referencia.getDownloadURL().subscribe((url) => {
              this.urlImages.push(url);
              this.formularioProducto.value.Imagenes = this.urlImages;
              this.addProducto();
            });
          });
          tarea.percentageChanges().subscribe((porcentaje) => {
            this.porcentajeSubida = parseInt(porcentaje.toString());
          });
        }
        else {
          this.msj.mensajeError('Error', 'La imágen excede 512 KB')
        }
      }
      else {
        this.msj.mensajeError('Error', 'Max 3 imágenes por producto')
      }

    }
  }

  addProducto()
  {
    if(this.formularioProducto.value.CostoEnvio == undefined) {
      this.formularioProducto.value.CostoEnvio = 0
    }
    this.formularioProducto.value.ImgUrl = this.urlImage;
    this.formularioProducto.value.User=this.expositor;
    this.formularioProducto.value.TallerEstado = this.expositorInfo.Estado;
    this.formularioProducto.value.TallerLineaProducto = this.expositorInfo.LineaProducto1;
    this.formularioProducto.value.Taller = this.expositorInfo.NombreTaller;
    this.formularioProducto.value.CostoEnvioLocal = this.expositorInfo.CostoEnvioLocal;
    this.formularioProducto.value.CostoEnvioNacional = this.expositorInfo.CostoEnvioNacional;
    this.formularioProducto.value.Imagenes = this.urlImages;
    this.formularioProducto.value.Ciudad = this.expositorInfo.Ciudad;
    this.formularioProducto.value.Departamento = this.expositorInfo.Departamento
    this.formularioProducto.value.EpaycoID = (this.expositorInfo.EpaycoID) ? this.expositorInfo.EpaycoID : null;
    this.formularioProducto.value.Comision = (this.expositorInfo.Comision >= 0) ? this.expositorInfo.Comision : null;
    this.formularioProducto.value.Iva = (this.expositorInfo.Iva >= 0) ? this.expositorInfo.Iva : null;


    if(this.formularioModo=="creacion")
    {
      if(this.arrayProductos.length < this.params.ProductosCantidad)
      {
        const valor = this.formularioProducto.value.Valor;
      const porcentaje = this.formularioProducto.value.PorcentajeDescuento;
      this.formularioProducto.value.ValorConDescuento = valor - (valor * porcentaje/100 )
        this.formularioProducto.value.FechaCreacion = new Date();
        this.formularioProducto.value.UsuarioCreacion = sessionStorage.getItem('User');
        this.db.collection('Productos').add(this.formularioProducto.value).then((item) => {
          this.msj.mensajeCorrecto('Agregar', 'Producto agregado correctamente');
          this.urlImage="";
          this.porcentajeSubida=0;
          this.formularioProducto.reset();
          this.loadTable(this.expositor);
          this.closeForm();
        });

        this.db.collection('HistorialProductos').add(this.formularioProducto.value).then((item) => {
        });
      }
      else
      {
        this.msj.mensajeError('Mensaje', 'Solo se pueden subir 10 productos.');
      }
    }
    else
    {
      const valor = this.formularioProducto.value.Valor;
      const porcentaje = this.formularioProducto.value.PorcentajeDescuento;
      this.formularioProducto.value.ValorConDescuento = valor - (valor * porcentaje/100 )
      this.formularioProducto.value.FechaModificacion = new Date();
      this.formularioProducto.value.UsuarioModificacion = sessionStorage.getItem('User');
      this.db.doc('Productos/' + this.idProducto).update(this.formularioProducto.value).then((item) => {
        this.msj.mensajeCorrecto('Actualizado', 'Producto actualizado correctamente');
        this.urlImage="";
        this.porcentajeSubida=0;
        this.formularioProducto.reset();
        this.loadTable(this.expositor);
        this.closeForm();
      });
      this.db.collection('HistorialProductos').add(this.formularioProducto.value).then((item) => {
      });
    }
  }

  deleteProducto(value)
  {
    this.db.collection("Productos").doc(value).delete().then((item)=> {
      this.msj.mensajeCorrecto('Eliminar', 'Producto eliminado correctamente');
      this.loadTable(this.expositor);
    });
  }

  openFormNew(){
    this.formularioModo="creacion";
  }

  closeForm(){
    this.formularioModo="lista";
    this.formularioProducto.reset();
    this.urlImage = '';
  }

  openFormEdit(idProducto){
    this.idProducto=idProducto;
    this.formularioModo="edicion";
    this.db.doc<any>('Productos' + '/' + idProducto).valueChanges().subscribe((producto) =>{
      this.setForm(producto);
    });
  }

  setForm(producto){
    this.formularioProducto.patchValue({
      Nombre: producto.Nombre,
      Descripcion: producto.Descripcion,
      DescripcionCorta: producto.DescripcionCorta,
      Valor: producto.Valor,
      ValorConDescuento: producto.ValorConDescuento,
      PorcentajeDescuento: producto.PorcentajeDescuento,
      Stock: producto.Stock,
      // ProductoNuevo: producto.ProductoNuevo,
      // Saldo: producto.Saldo,
      Tags: producto.Tags,
      TiempoEntrega: producto.TiempoEntrega,
      EpaycoID: producto.EpaycoID ? producto.EpaycoID : null,
      Comision: producto.Comision >= 0 ? producto.Comision : null,
      Iva: producto.Iva >= 0 ? producto.Iva : null

    });
      this.urlImage = producto.ImgUrl;
      this.urlImages = producto.Imagenes
  }

  // onCostoNoIncluido(active){
  //   if(active){
  //     this.costoNoIncluido = true;
  //   }
  //   else {
  //     this.costoNoIncluido = false;
  //   }
  // }
}
