import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  public currentUserObservable: Observable<any>;
  public currentUser: any;
  public currentRole: any;
  public displayName: any;

  constructor(public auth: AngularFireAuth,
              private db: AngularFirestore,
              private router: Router,
              private mensajesService:MensajesService) {

              this.currentUserObservable = new BehaviorSubject<any>(sessionStorage.getItem('User')).asObservable();
              this.auth.authState.subscribe(user => {
                if(!user){
                  sessionStorage.removeItem('User');
                  sessionStorage.removeItem('Role');
                }
                else {
                  this.currentUser = sessionStorage.getItem('User');
                  this.currentRole = sessionStorage.getItem('Role');
                  this.displayName = sessionStorage.getItem('DisplayName');
                }
              })
  }

  logout() {
    this.auth.signOut()
    .then(result => {
      sessionStorage.removeItem('User');
      sessionStorage.removeItem('Role');
      sessionStorage.removeItem('DisplayName');
      // this.router.navigate(['login']);
      window.location.reload();
    })

  }

  get isLoggedIn(): boolean {
    const user = sessionStorage.getItem('User');
    return (user !== null) ? true : false;
  }

  login(username,password)
  {
     this.auth.signInWithEmailAndPassword(username, password).then((user) => {
        this.currentUser=user;
        sessionStorage.setItem('User',this.currentUser.user.email);
        sessionStorage.setItem('DisplayName',this.currentUser.user.email);
        this.setRole(user);
      }).catch((error) => {
        sessionStorage.removeItem('User');
        sessionStorage.removeItem('Role');
        sessionStorage.removeItem('DisplayName');
        switch (error.message){
          case "The password is invalid or the user does not have a password.":
            this.mensajesService.mensajeAdvertencia("Advertencia","La contraseña es invalidad o el usuario no existe, Intente de nuevo.");
            break;
          case "Too many unsuccessful login attempts. Please try again later.":
            this.mensajesService.mensajeAdvertencia("Advertencia","Tiene muchos intentos fallidos, por favor intente mas tarde, si no recuerda su contraseña contacte al administrador.");
            break;
          default:
            this.mensajesService.mensajeAdvertencia("Advertencia",error.message);
            break;
        }
      })
  }

  setRole(user){
    this.db.collection('Administradores').get().subscribe((admins) => {
      let isAdmin=false;
      for (let index = 0; index < admins.docs.length; index++) {
        const data = admins.docs[index].data();
        if(data.email==user.user.email){
          isAdmin=true;
          this.currentRole= 'Admin';
          this.router.navigate(['expositores']);
        }
      }
      if(!isAdmin)
      {
        this.currentRole='Expositor';
      }
      sessionStorage.setItem('Role',this.currentRole);
      if(this.currentRole=="Expositor")
      {
        this.router.navigate(['expositor']);
      }
    });
  }

  createUserAuth(email, password){
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  forgetPassword(email){
    return this.auth.sendPasswordResetEmail(email);
  }
}
