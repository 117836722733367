// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyARGzIQ7WgAetaFc-paAtnse_lqhnn5IZM",
    authDomain: "admin-infondo.firebaseapp.com",
    projectId: "admin-infondo",
    storageBucket: "admin-infondo.appspot.com",
    messagingSenderId: "1052438305281",
    appId: "1:1052438305281:web:64768fb9d65d58e7aabe00"
  }
};
// const firebaseConfig = {
//   apiKey: "AIzaSyARGzIQ7WgAetaFc-paAtnse_lqhnn5IZM",
//   authDomain: "admin-infondo.firebaseapp.com",
//   projectId: "admin-infondo",
//   storageBucket: "admin-infondo.appspot.com",
//   messagingSenderId: "1052438305281",
//   appId: "1:1052438305281:web:64768fb9d65d58e7aabe00"
// };
if(location.hostname == 'localhost'){
  environment.production = false;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
