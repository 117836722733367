import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ClasificadosService {

  constructor(private db: AngularFirestore) { }

  addClasificado(data){
    return this.db.collection('Clasificados').add(data);
  }

  getClasificados(){
    return this.db.collection('Clasificados').get();
  }
}
