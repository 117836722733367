import { Routes, RouterModule} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ExpositoresComponent } from './components/expositores/expositores.component';
import { StandsComponent } from './components/stands/stands.component';
import { HomeComponent } from './components/home/home.component';
import { ProductosComponent } from './components/productos/productos.component';
import { BannerComponent } from './components/banner/banner.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ExpositoresServiciosComponent } from './components/expositoresservicios/expositoresservicios.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { ExpositorComponent } from './components/expositor/expositor.component';
import { CarguemasivoComponent } from './components/carguemasivo/carguemasivo.component';
import { ExpositoreswebpageComponent } from './components/expositoreswebpage/expositoreswebpage.component';
import { OrdenesComponent } from './components/ordenes/ordenes.component';
import { AdministradorComponent } from './components/administrador/administrador.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { AuthGuard } from './auth.guard';
import { ClasificadosComponent } from './components/clasificados/clasificados.component';
const app_routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'categorias', component: CategoriasComponent, canActivate: [ AuthGuard ]},
    {path: 'recuperar-contrasena', component: ForgotPasswordComponent},
    {path: 'administrador', component: AdministradorComponent, canActivate: [ AuthGuard ]},
    {path: 'expositor', component: ExpositorComponent, canActivate: [ AuthGuard ]},
    {path: 'expositor/:expositorID', component: ExpositorComponent, canActivate: [ AuthGuard ]},
    {path: 'expositores', component: ExpositoresComponent, canActivate: [ AuthGuard ]},
    {path: 'clientes', component: ClientesComponent, canActivate: [ AuthGuard ]},
    {path: 'expositores/:expositorID', component: ExpositoresComponent, canActivate: [ AuthGuard ]},
    {path: 'stands', component: StandsComponent, canActivate: [ AuthGuard ]},
    {path: 'home', component: HomeComponent, canActivate: [ AuthGuard ]},
    {path: 'banner', component:BannerComponent, canActivate: [ AuthGuard ]},
    {path: 'ordenes', component:OrdenesComponent, canActivate: [ AuthGuard ]},
    {path: 'clasificados', component: ClasificadosComponent, canActivate: [ AuthGuard ]},
    {path: 'reportes', component: ReportesComponent, canActivate: [ AuthGuard ]},
    {path: 'productos', component:ProductosComponent, canActivate: [ AuthGuard ]},
    {path: 'productos/:expositorID', component: ProductosComponent, canActivate: [ AuthGuard ]},
    {path: 'servicios', component:ServiciosComponent, canActivate: [ AuthGuard ]},
    {path: 'servicios/:servicioID', component: ServiciosComponent, canActivate: [ AuthGuard ]},
    {path: 'expositoresservicios', component:ExpositoresServiciosComponent, canActivate: [ AuthGuard ]},
    {path: 'expositoresservicios/:expositoresservicioID', component: ExpositoresServiciosComponent, canActivate: [ AuthGuard ]},
    {path: 'carguemasivo', component:CarguemasivoComponent, canActivate: [ AuthGuard ]},
    {path: 'pagos', component:PagosComponent, canActivate: [ AuthGuard ]},
    {path: 'mapa', component:MapaComponent, canActivate: [ AuthGuard ]},
    {path: 'expositoreswebpage', component:ExpositoreswebpageComponent, canActivate: [ AuthGuard ]},
    {path: '', component: HomeComponent, canActivate: [ AuthGuard ]},
    {path: '**', pathMatch: 'full', redirectTo: ''}
];

export const app_routing = RouterModule.forRoot(app_routes);
