import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  constructor(private db: AngularFirestore) { }

  getParametros(){
    return this.db.collection('Parametros').get();
  }
  updateParametros(data){
    return this.db.collection('Parametros').doc('vnCSV3xP8ycD969imWlj').update(data);
  }
}
