import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClasificadosService } from 'src/app/services/clasificados.service';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-clasificados',
  templateUrl: './clasificados.component.html',
  styleUrls: ['./clasificados.component.css']
})
export class ClasificadosComponent implements OnInit {

  public formularioClasificado: FormGroup;
  public clasificados = [];

  constructor(private formBuilder: FormBuilder,
              private clasificadoSvc: ClasificadosService,
              private msjSvc: MensajesService) { }

  ngOnInit(): void {
    this.formularioClasificado = this.formBuilder.group({
      Correo: ['', Validators.required ],
      Descripcion: ['', Validators.required]
    });
    this.getClasificados();
  }

  getClasificados(){
    this.clasificadoSvc.getClasificados().subscribe(clasificados => {
      clasificados.docs.forEach(clasificado => {
        this.clasificados.push({
          id: clasificado.id,
          data: clasificado.data()
        })
      });
    })
  }

  addClasificado(){

    if(this.formularioClasificado.valid){
      this.clasificadoSvc.addClasificado(this.formularioClasificado.value)
      .then(result => {
        this.clasificados.push({
          id: result.id,
          data: this.formularioClasificado.value
        });
        this.msjSvc.mensajeCorrecto('Ok', 'Clasificado agregado correctamente')
        this.formularioClasificado.reset();
      })
      .catch(err => {
        this.msjSvc.mensajeError('Error', 'Error no se pudo guardar clasificado')
      })
    }
  }

}
